
import RichTextEditor from '@/components/inputs/RichTextEditor.vue'
import Modal from '@/components/actions/Modal.vue'
import { DropdownListItem } from 'tradingmate-modules/src/forms'
import EmailTemplateRevision from 'tradingmate-modules/src/models/api/admin/email-templates/EmailTemplateRevision'
import EmailTemplateRevisionUpdate from 'tradingmate-modules/src/models/api/admin/email-templates/EmailTemplateRevisionUpdate'
import EmailTemplateRevisionCreate from 'tradingmate-modules/src/models/api/admin/email-templates/EmailTemplateRevisionCreate'
import { Services } from 'tradingmate-modules/src/services'
import { Component, Mixins } from 'vue-property-decorator'
import { FormPage } from '@/mixins'
import Utils from 'tradingmate-modules/src/Utils'
import { InputSelect, InputText } from '@/components/inputs'
import InfoState from '@/components/status/InfoState.vue'
import EmailTemplate from 'tradingmate-modules/src/models/api/admin/email-templates/EmailTemplate'
import SendTestEmailRequest from 'tradingmate-modules/src/models/api/admin/email-templates/SendTestEmailRequest'
import EmailTemplateType from 'tradingmate-modules/src/models/api/admin/email-templates/EmailTemplateType'

@Component({
  components: {
    RichTextEditor,
    Modal,
    InputSelect,
    InfoState,
    InputText
  }
})

export default class EmailTemplateEdit extends Mixins(FormPage) {
  private template: EmailTemplate | null = null
  private revisions: EmailTemplateRevision[] | null = null
  private masterRevision: EmailTemplateRevision | null = null
  private gettingFile = false
  private selectedRevision: EmailTemplateRevision | null = null
  private customType = EmailTemplateType.Custom

  private showSaveModal = false;
  private showSaveAsModal = false;

  private isoToDisplayDate = Utils.isoToDisplayDate
  private isoToDisplayTime = Utils.isoToDisplayTime

  private selectValue = ''

  get content (): string | null {
    if (!this.masterRevision || !this.selectedRevision) return null
    let content = this.masterRevision.HtmlContent
    content = content.replace('{Content}', this.selectedRevision.HtmlContent)
    return content
  }

  get currentRevision (): EmailTemplateRevision | null {
    if (!this.template || !this.revisions) return null
    // if (!this.revisions) return
    var current = this.revisions.find(x => x.RevisionId === this.template?.EmailTemplateId)
    if (!current) return null
    return current
  }

  async mounted (): Promise<void> {
    await this.getTemplate()
    await this.getMaster()
    this.getRevisions()
  }

  private gettingTemplate = false
  async getTemplate (): Promise<void> {
    const id = this.$route.query.id.toString()
    console.log(id)
    if (id === null) { console.log('no id found'); return }
    this.gettingTemplate = true
    await Services.API.Admin.Emails.GetEmailTemplate(id)
      .then((response) => {
        this.template = response
        console.log('getTemplate this.template')
        console.log(this.template)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        this.gettingTemplate = false
      })
  }

  private gettingRevisions = false
  getRevisions (): void {
    if (!this.template) { console.log('no template'); return }
    this.gettingRevisions = true
    Services.API.Admin.Emails.GetRevisions(this.template.EmailTemplateId)
      .then((revisions) => {
        this.revisions = revisions
        console.log('get revisions this.revisions')
        console.log(this.revisions)
        if (!revisions) return
        this.selectedRevision = this.findCurrentRevision()
        if (this.selectedRevision)
        {
          this.selectValue = this.selectedRevision?.RevisionId
        }
      })
      .catch((errors) => { this.httpError = errors })
      .finally(() => { this.gettingRevisions = false })
  }

  private gettingMaster = false
  async getMaster (): Promise<void> {
    if (!this.template) { console.log('no template') }
    this.gettingMaster = true
    await Services.API.Admin.Emails.GetMasterEmailTemplateRevision()
      .then((result) => { this.masterRevision = result })
      .catch((error) => { console.log(error) })
      .finally(() => { this.gettingMaster = false })
  }

  onRevisionSelect (): void {
    var selected = this.revisions?.find(x => x.RevisionId === this.selectValue)
    // if (selected === null) return null
    this.selectedRevision = selected ?? null
  }

  // findCurrentRevision (): EmailTemplateRevision | undefined {
  //   if (this.revisions === null) return
  //   console.log('revision')
  //   console.log(this.revisions)
  //   const revision = this.revisions.find(x => x.Current === true)
  //   console.log('current revision')
  //   console.log(revision)
  //   return revision
  // }

  selectRevision (revision: EmailTemplateRevision): void {
    console.log('selectRevision')
    console.log(revision)
    if (revision === null) return
    this.selectedRevision = revision
  }

  private savingRevision = false
  handleSave (): void {
    if (this.selectedRevision === null) return
    this.savingRevision = true
    const revisionSave: EmailTemplateRevisionUpdate = {
      HtmlContent: this.selectedRevision.HtmlContent,
      Subject: this.selectedRevision.Subject,
      Comment: this.selectedRevision.Comment
    }
    Services.API.Admin.Emails.RevisionUpdate(this.selectedRevision.RevisionId, revisionSave)
      .then(() => { this.showSaveModal = false })
      .catch((errors) => { this.httpError = errors })
      .finally(() => { this.savingRevision = false })
  }

  // getRevisionFile (revisionId: string): void {
  //   this.gettingFile = true
  //   console.log('getting content')
  //   console.log(revisionId)
  //   Services.API.Emails.GetRevisionContent(revisionId)
  //     .then((html) => { console.log('revision file'); this.revisionFile = html.Content; console.log(this.revisionFile) })
  //     .catch((errors) => { this.httpErrors = errors })
  //     .finally(() => { this.gettingFile = false })
  // }

  findCurrentRevision (): EmailTemplateRevision | null {
    if (!this.template || !this.revisions) return null
    // if (!this.revisions) return
    var current = this.revisions.find(x => x.RevisionId === this.template?.CurrentRevisionId)
    if (!current) return null
    return current
  }

  isCurrent (revisionId: string): boolean {
    if (this.template?.CurrentRevisionId === revisionId) return true
    return false
  }

  get revisionItems (): DropdownListItem[] {
    if (this.revisions === null) return []
    const items: DropdownListItem[] = []
    this.revisions.forEach(element => {
      items.push({ Key: element.RevisionId, Label: (this.isCurrent(element.RevisionId) ? '(Current) ' : '') + element.Name + ' - ' + this.isoToDisplayDate(element.Created) + ' ' + this.isoToDisplayTime(element.Created), Value: element.RevisionId })
    })
    return items
  }

  private creatingRevision = false
  private revisionCreateName = ''
  handleCreate (): void {
    if (this.selectedRevision === null) return
    const templateId = this.$route.query.id?.toString() ?? undefined
    if (!templateId) return
    const revisionCreate: EmailTemplateRevisionCreate = {
      Name: this.revisionCreateName,
      HtmlContent: this.selectedRevision.HtmlContent,
      Subject: this.selectedRevision.Subject,
      Comment: this.selectedRevision.Comment
    }
    this.creatingRevision = true
    Services.API.Admin.Emails.RevisionCreate(templateId, revisionCreate)
      .then((returnRevision) => {
        this.showSaveAsModal = false
        this.revisionCreateName = ''
        if (this.revisions == null) return
        this.revisions.push(returnRevision)
        this.selectedRevision = returnRevision
      })
      .catch((errors) => { this.httpError = errors })
      .finally(() => { this.creatingRevision = false })
  }

  private changingCurrentRevision = false
  changeCurrentRevision (): void {
    if (!this.selectedRevision || !this.template) return
    this.changingCurrentRevision = true
    Services.API.Admin.Emails.RevisionChangeCurrent(this.template.EmailTemplateId, this.selectedRevision.RevisionId)
      .then((success) => {
        if (!this.template || !this.selectedRevision) return
        this.template.CurrentRevisionId = this.selectedRevision.RevisionId
      })
      .catch((errors) => { this.httpError = errors })
      .finally(() => { this.changingCurrentRevision = false })
  }

  private isSendingTest = false
  onSendTest (): void {
    if (!this.selectedRevision || !this.template) return
    this.isSendingTest = true
    var request: SendTestEmailRequest =
    {
      HtmlContent: this.selectedRevision.HtmlContent,
      Subject: this.selectedRevision.Subject,
      Category: this.template.Category,
      Type: this.template.Type
    }
    Services.API.Admin.Emails.SendTest(request)
      .then((response) => {
        if (response) console.log('Email Sent')
        else console.log('Email Failed')
      })
      .catch((errors) => {
        console.log(errors)
      })
      .finally(() => {
        this.isSendingTest = false
      })
  }

  private isDeleting = false
  onDelete (): void {
    this.isDeleting = true
    if (!this.selectedRevision || !this.template) return
    Services.API.Admin.Emails.TempalteDelete(this.template.EmailTemplateId)
      .then((result) => {
        if (result !== true) return
        this.$router.push({ name: 'Email Templates' })
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        this.isDeleting = false
      })
  }
}
